const transportBillStatus = {
  30: {
    text: '已接单',
    color: '#FA8C16',
    finished: false,
    btnText: '装货发车'
  },
  40: {
    text: '已装车',
    color: '#00A7AB',
    finished: false,
    btnText: '卸货到达'
  },
  50: { text: '已卸货', color: '#2BAD40', finished: true },
  60: { text: '已签收', color: '#BCBCBC', finished: true },
  70: { text: '已签收', color: '#BCBCBC', finished: true }
};
export default transportBillStatus;
