<template>
  <li class="card mb-12 px-16 py-12" @click="handleToDetail(item.id)">
    <div class="line1">
      <img class="icon" src="@/assets/icon_company.png" />
      <p v-if="item.appDisplayShipperInfo === 1" class="address text_secondary">
        {{ item.shipperInfoDTO && item.shipperInfoDTO.shipperName }}
      </p>
      <p
        class="status"
        :style="{
          color: transportBillStatus[item.transportBillStatus].color
        }"
      >
        {{ transportBillStatus[item.transportBillStatus].text }}
      </p>
    </div>
    <div class="line2 mt-24">
      <div class="left">
        <p class="county text-truncate">
          {{ item.addressInfoDTO.senderDistrictName }}
        </p>
        <p class="city text-truncate">
          {{ item.addressInfoDTO.senderCityName }}
        </p>
      </div>
      <div class="center">
        <p class="goods text-truncate">
          {{ item.goodsInfoDTO.goodsName }}
        </p>
        <div class="decorate">
          <div class="circle"></div>
          <div class="line"></div>
          <div class="circle"></div>
        </div>
      </div>
      <div class="right">
        <p class="county text-truncate">
          {{ item.addressInfoDTO.receiverDistrictName }}
        </p>
        <p class="city text-truncate">
          {{ item.addressInfoDTO.receiverCityName }}
        </p>
      </div>
    </div>
    <div v-if="item.transportBillStatus < 50" class="line3 flex items-center justify-between">
      <div class="left">
        <div class="d-flex" v-if="item.appShowPrice !== 0">
          <p class="price"><span>￥</span>{{ item.carrierInfoDTO.carrierTransportCost / 100 }}</p>
          <p class="status">{{ item.payStatusStr }}</p>
        </div>
        <div class="date">{{ formatToDateTime(item.transportBillTime, 'MM月dd日 hh:mm') }}接单</div>
      </div>
      <Button
        class="button"
        type="primary"
        @click.stop="handleClickBtn(item.transportBillStatus, item.sign, item.id)"
        >{{
          item.transportBillStatus === 30 && !item.sign
            ? '立即签约'
            : transportBillStatus[item.transportBillStatus].btnText
        }}</Button
      >
    </div>
    <div v-else class="line3  flex items-center justify-between">
      <div class="left">
        <div class="d-flex" v-show="item.appShowPrice !== 0">
          <p class="price grey"><span>￥</span>{{ item.carrierInfoDTO.carrierTransportCost / 100 }}</p>
          <p class="status grey">{{ item.payStatusStr }}</p>
        </div>
      </div>
      <div class="date date1">{{ formatToDateTime(item.transportBillTime, 'MM月dd日 hh:mm') }}接单</div>
    </div>
  </li>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import transportBillStatus from '../constants/transportBillStatus';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { Button } from 'vant';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  components: {
    Button
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    // 点击按钮
    const handleClickBtn = (status, sign, id) => {
      const driverId = userStore?.driverId;
      if (status === 30 && !sign) {
        router.push({
          path: PageEnum.CONTRACT_ONLINE,
          query: { driverId: driverId, routerBack: 1 }
        });
      } else if (status === 30 && sign) {
        router.push({
          path: PageEnum.WAYBILL_GOODSINFO,
          query: { id: id, type: 0 }
        });
      } else if (status === 40) {
        router.push({
          path: PageEnum.WAYBILL_GOODSINFO,
          query: { id: id, type: 1 }
        });
      }
    };

    // 跳到详情页
    const handleToDetail = id => {
      router.push({ path: PageEnum.DRIVER_WAYBILL_DETAIL, query: { id } });
    };
    return {
      handleClickBtn,
      handleToDetail,
      transportBillStatus,
      formatToDateTime
    };
  }
});
</script>
<style lang="less" scoped>
.card {
  background: #ffffff;
  border-radius: 0.08rem;
  box-shadow: 0px 0px 0.04rem 0px #ebf3fd;
  list-style: none;
  .line1 {
    overflow: hidden;
    .icon {
      float: left;
      height: 0.16rem;
      width: 0.16rem;
    }
    .address {
      height: 0.17rem;
      font-size: 0.12rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.17rem;
      margin-left: 0.08rem;
      float: left;
    }
    .status {
      height: 0.17rem;
      font-size: 0.12rem;
      font-weight: 400;
      color: #f56923;
      line-height: 0.17rem;
      float: right;
    }
  }
  .line2 {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      .county {
        font-size: 0.16rem;
        font-weight: 600;
        text-align: center;
        color: #1d1d1d;
        line-height: 0.22rem;
        width: 0.82rem;
        height: 0.22rem;
      }
      .city {
        font-size: 0.14rem;
        font-weight: 400;
        text-align: center;
        color: #444;
        line-height: 0.2rem;
        width: 0.8rem;
        height: 0.2rem;
        margin-top: 0.06rem;
      }
    }
    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      .goods {
        width: 0.72rem;
        height: 0.17rem;
        font-size: 0.12rem;
        font-weight: 400;
        text-align: center;
        color: #1d1d1d;
        line-height: 0.17rem;
        margin-top: 0.1rem;
      }
      .decorate {
        overflow: hidden;
        margin-top: 0.02rem;
        .circle {
          width: 0.08rem;
          height: 0.08rem;
          border: 0.01rem solid #dddddd;
          border-radius: 50%;
          float: left;
        }
        .line {
          width: 0.72rem;
          height: 0.01rem;
          border-top: 0.01rem solid #dddddd;
          float: left;
          margin-top: 0.04rem;
        }
      }
    }
    .right {
      float: right;
    }
  }
  .line3 {
    padding-top: 12px;
    border-top: 0.01rem solid #f5f5f5;
    margin-top: 0.24rem;
    overflow: hidden;
    .left {
      float: left;
      .price {
        height: 0.33rem;
        font-size: 0.22rem;
        font-weight: 600;
        text-align: center;
        color: #fa8c16;
        line-height: 0.33rem;
        span {
          font-size: 0.14rem;
        }
      }
      .status {
        height: 0.33rem;
        font-size: 0.12rem;
        font-weight: 400;
        color: #878787;
        line-height: 0.33rem;
        margin-left: 0.07rem;
      }
      .date {
        height: 0.17rem;
        font-size: 0.12rem;
        font-weight: 400;
        color: #bcbcbc;
        line-height: 0.17rem;
        margin-top: 0.02rem;
      }
      .grey {
        color: #bbbbbb !important;
      }
    }
    .button {
      margin-top: 0.07rem;
      float: right;
    }
    .date1 {
      float: right;
      height: 0.17rem;
      font-size: 0.12rem;
      font-weight: 400;
      color: #bbbbbb;
      line-height: 0.17rem;
      margin-top: 0.08rem;
    }
  }
}
</style>
